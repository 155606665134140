import * as icons from "../icons";
import google1999 from "./textFiles/google1999";
import facepalm from "./textFiles/facepalm";
import squirtel from "./textFiles/squirtel";
import rollin from "./textFiles/rollin";
import sunscreen from "./textFiles/sunscreen";
import allStarTabs from "./textFiles/allStarTabs";

const programs = [
  {
    title: "Notepad",
    icon: icons.notepad16,
    component: "Notepad",
    multiInstance: true
  },
  {
    title: "SkiFree",
    icon: icons.skifree,
    component: "IframeWindow",
    data: {
      src: "https://basicallydan.github.io/skifree.js/"
    },
    multiInstance: true
  },
];

const startMenuData = [
  {
    title: "Buy $A token",
    icon: icons.ape,
    options: [
      {
        title: "Coming soon",
        icon: icons.htmlFile32,
        isDisabled: true
      },
    ]
  },
  {
    title: "Programs",
    icon: icons.folderProgram24,
    options: programs
  },
];

export default startMenuData;
