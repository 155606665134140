import * as images from "../../images";

const generateRows = () => {
  const rows = [
    {
      content: "In the bustling society of Solana, meme tokens ruled the scene, each vying for the attention of the crypto-savvy citizens. <br><br>Funny Ape, with a comical grin and a vision for token \"A\", stepped into the spotlight, dedicating 18% of the tokenomics to marketing and 82% to empowering his vibrant community. As the society buzzed with excitement, token \"A\" became the talk of the town, its value climbing higher with each passing day. <br><br>With every move and gesture, Funny Ape proved that in the world of meme tokens, community and creativity could conquer all!"
    },
  ];

  return rows
    .map(
      r => `
    <tr>
      <td style="padding: 25px 20px">
        ${r.content}
      </td>
    </tr>
  `
    )
    .join("");
};

const marqueeGen = () =>
  [
    {
      title: "Funny Ape's Token $A : Swinging into the Future of Fun on Solana!"
    }
  ]
    .map(
      l =>
        `<div style="color: #fff;">${
          l.title
        }</div>`
    )
    .join(" | ");

const readmeHTML = `
<style>
font * {font-family: 'Comic Sans MS' !important;}
blink {
  -webkit-animation: 1s linear infinite condemned_blink_effect; // for android
  animation: 1s linear infinite condemned_blink_effect;
}
@-webkit-keyframes condemned_blink_effect { // for android
  0% {
      visibility: hidden;
  }
  50% {
      visibility: hidden;
  }
  100% {
      visibility: visible;
  }
}
@keyframes condemned_blink_effect {
  0% {
      visibility: hidden;
  }
  50% {
      visibility: hidden;
  }
  100% {
      visibility: visible;
  }
}
</style>
<font size="3">
  <marquee bgcolor="#212733" color="#fff" style="border-bottom: 1px solid #000">
    ${marqueeGen()}
  </marquee>
  <table width="100%" style="border-spacing: 0; margin-top: -3px;">
    <thead>
      <tr>
        <td colspan="2" valign="center" style="padding: 0;">
          <img src="${images.apeBanner}" width="100%" style="display: block"/>
        </td>
      </tr>
    </thead>
    <tbody valign="top">
      ${generateRows()}
    </tbody>
    <tfoot valign="top">
      <tr>
        <td style="padding: 20px; border-top: 1px solid #000;">Funny Ape © 2024</td>
      </tr>
    </tfoot>
  </table>
 </font>
`;

export default readmeHTML;
