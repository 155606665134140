import * as icons from "../icons";
import resume from "./textFiles/resume";
import readme from "./textFiles/readme";

const desktopData = [
  {
    title: "Social",
    icon: icons.world,
    component: "ExplorerWindow",
    data: {
      content: [
        {
          title: "X",
          icon: "X",
          href: "https://x.com/sol_memetoken_A",
          external: true,
        },
        {
          title: "Telegram",
          icon: "telegram",
          href: "https://t.me/sol_memetoken_A",
          external: true,
        },
      ]
    }
  },
  {
    title: "Buy $A token",
    icon: icons.ape,
    component: "ExplorerWindow",
    data: {
      content: [
        {
          title: "Coming soon",
          icon: "htmlFile16",
          href: "#",
          external: true,
        },
      ]
    }
  },
  {
    title: "Internet Explorer",
    icon: icons.htmlFile32,
    component: "InternetExplorer",
    data: {
      __html: readme
    }
  },
  {
    title: "SkiFree",
    icon: icons.skifree,
    component: "IframeWindow",
    data: {
      src: "https://basicallydan.github.io/skifree.js/"
    },
    multiInstance: true
  }
];

export default desktopData;
